<template>
	<div class="page" >
		<div class="container">
			<div class="notes">
				<h1>请{{data.isOldCustomer ? '确认' : '填写'}}您的个人信息</h1>
				<h4>*为必填项</h4>
			</div>
			<div class="content">
				<div class="form">

					<template v-for="(item, idx) in style.informationOptions">
						<van-field v-if="item.isShow" :key="idx">
							<template #label>
								<div v-if="item.id === 'companyAdd'" class="other-label">
									<span>公司<br />地址</span>
									<span v-if="item.isRequired">&nbsp;*</span>
								</div>
								<div v-else class="label">
									<span>{{item.title}}</span>
									<span v-if="item.isRequired">&nbsp;*</span>
								</div>
							</template>
							<template #input>
								<!-- 
								<input v-if="item.id === 'companyAdd'" v-model.trim="data[`${item.id}`]" type="text" :ref="item.id"
								 class="input" :style="{color: style.btnColor}" placeholder="请输入至少5个字符">
								<input v-else v-model.trim="data[`${item.id}`]" type="text" :ref="item.id" class="input" :style="{color: style.btnColor}"
								 :placeholder="`请输入${item.title}`">
								-->
								
								<div v-if="item.id === 'area'">
									<input v-model.trim="data[`${item.id}`]" type="text" readonly @click="isShowAreaList = true" :ref="item.id" class="input" :placeholder="`请选择${item.title}`">
								</div>
								<div v-else class="label">
									<input v-model.trim="data[`${item.id}`]" type="text" :ref="item.id" class="input" :placeholder="`请输入${item.title}`">
								</div>
							</template>
						</van-field>
					</template>
					
					<!-- 省市区选择列表 -->
					<van-popup v-model="isShowAreaList" position="bottom" :style="{ height: '45%' }" :close-on-click-overlay="false" safe-area-inset-bottom >
						<van-area :area-list="areaList" :value="selected" @cancel="close" @confirm="confirm" />
					</van-popup>

				</div>

				<!-- 老客户显示 “重新上传名片”-->
				<van-button v-if="data.isOldCustomer && style.isAllowSwitch" type="primary" block :color="style.btnColor" class="next-btn radius" @click="shootCard">重新上传名片</van-button>

				<van-button type="primary" block :color="style.btnColor" class="next-btn radius" @click="nextStep">下一步</van-button>
			</div>
		</div>
		<div class="footer">
			<bottom :mode="style.mode" :level="style.level"></bottom>
		</div>
	</div>
</template>

<script>

	import commonMixin from '@/view/process/mixin/common.js';
	import informationMixin from '@/view/process/mixin/information.js';

	export default {
		mixins: [commonMixin, informationMixin],
		data() {
			return {
				
			}
		},
		created() {
			this.setLanguge('zh'); // 设置语言环境
		},
		methods: {
			// 确定省市区选择
			confirm(e) {
				// e: [{"code":"130000","name":"河北省"},{"code":"130500","name":"邢台市"},{"code":"130529","name":"巨鹿县"}]
				this.data.provinceName = e[0].name;
				this.data.cityName = e[1].name;
				this.data.countyName = e[2].name;
				this.data.area = e[0].name + e[1].name + e[2].name
				this.close();
			},
			// 关闭省市区选择列表
			close() {
				this.isShowAreaList = false
			},
		},
	}
</script>

<style lang="less" scoped>
	// @color: #9F9D9D;
	@color: #6D6D6D;
	
	.notes {
		margin: 5vh auto 2vh;

		h1,
		h4 {
			text-align: center;
			color: @color;
		}

		h1 {
			font-size: 24px;
			margin-bottom: 2vh;
		}

		h4 {
			font-size: 14px;
		}
	}

	.content {
		margin-bottom: 2vh;
		min-height: 50vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.form {
			margin: 0 40px 5vh;


			.input {
				width: 100%;
				border: none;

				&::placeholder {
					color: #bbb;
				}
			}

			.other-label {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				line-height: 18px;
				padding-top: 4px;
			}

			.label {
				display: flex;
				justify-content: space-between;
				height: 40px;
				line-height: 40px;
			}

			/deep/ .van-cell {
				background-color: transparent;
				padding-top: 1.5vh;
				padding-bottom: 1.5vh;

				.van-field__label {
					width: 3em;
					height: 40px;
					font-size: 14px;
					margin-right: 8px;

					&>span {
						line-height: 44px;
					}
				}

				.van-field__value {
					line-height: 40px;
					padding: 0 10px;
					font-size: 14px;
					background-color: #fff;
					border-radius: 5px;
				}
			
				&::after {
					border-bottom: none;
				}
			}
		}

		.next-btn {
			width: 260px;
			margin: 0 auto 8px;
		}
	}
</style>
